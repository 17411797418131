import React from 'react'
import { Link } from 'react-router-dom';
import "../App.css";
import logo from "../Assets/Images/logo.png";

function Footer() {
  return (
    <div>
        <div className="copyright p-4 copyrightfooter">
  <div className="container">
    <div className="row">
      <div className="col-xs col-sm-12 col-md-4 col-lg-4">
        <p className="m-0 footerlogo">
          {" "}
          <img src={logo} />
        </p>
        <p className="text-white">EMPOWER. PROTECT. COMPETE. </p>
      </div>
      <div className="col-xs col-sm-12 col-md-4 col-lg-4">
        <p className="m-0 copyrighthana">
          ©Copyright Hana Software, Inc. 2023. All rights reserved.
        </p>
      </div>
      <div className="col-xs col-sm-12 col-md-4 col-lg-4 text-end">
        <div className="privacy">
          <ul 
          style={{
            width:"100%",
            display:'flex',
            justifyContent:'end',
            listStyle: 'none'
          }}
          className="list-inline1 mt-4"
          >
            <li
            className="list-inline-item3"
            >
              <Link 
              style={{
                marginRight:"5px"
              }}
              to="/privacypolicy">Privacy Policy</Link>
            </li>
            <li
            className="list-inline-item3"
            >
            <Link to="/termofservice"
            style={{
              marginLeft:"15px"
            }}
            >Terms of Service</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default Footer